<template>
<v-row>
    <v-col cols="12" md="6">
        <s-crud formPermanent no-full title="Mantenimiento Canal"  :config="this.config" @save="save($event)" add edit remove @rowSelected="rowSelected($event)" searchInput>
            <template slot-scope="props">
                <v-container v-if="props.item != null" class="pb-0">
                    <v-row justify="center">

                        <v-col cols="12" lg="12" md="12">
                            <s-text v-model="props.item.ChnDescription" label="Descripcion" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
                <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
            </template>
        </s-crud>
    </v-col>

</v-row>
</template>

<script>
import _sVisitsChannel from "@/services/Visits/VstChannel";

export default {

    data: () => ({
        config: {
            model: {
                ChnID: "ID",
                ChnDescription: "string",
                SecStatus: ""

            },

            service: _sVisitsChannel,
            headers: [{
                    text: "ID",
                    value: "ChnID",
                    sorteable: false,
                    width: 10
                },
                {
                    text: "Descripcion",
                    value: "ChnDescription",
                    sorteable: false,
                    width: 300
                },
                {
                    text: "Estado",
                    value: "SecStatus",
                    sorteable: false,
                    width: 10
                },

            ],
        },
    }),
    created() {

    },
    methods: {
        rowSelected(items) {

        },

        save(item) {
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID()

             if (item.ChnDescription.length == "" || item.ChnDescription == null || item.ChnDescription.length == 0) {
                 this.$fun.alert("Ingrese descripcion", "warning")
                 return
             }

            console.log(item)
            item.save()
        },

    },

};
</script>
